var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "login-wrapper", staticStyle: { background: "#f8f9f9" } },
    [
      _c("main", [
        _c(
          "div",
          {
            staticClass: "card card-body login-card shadow border-0 login-card",
            staticStyle: { "margin-top": "10vh" },
          },
          [
            _c("div", [
              _setup.logoUrl
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "logo-wrapper my-3 text-center d-flex justify-content-center align-items-center",
                      },
                      [
                        _c("img", {
                          staticClass: "login-logo",
                          attrs: { src: _setup.logoUrl, alt: "logo" },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("hr"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("div", [
                _setup.authenticationError
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _c("strong", [_vm._v("Login fehlgeschlagen!")]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _setup.errorMsg
                        ? _c("span", [_vm._v(_vm._s(_setup.errorMsg))])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _setup.doLogin()
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "username" } }, [
                        _vm._v("Nutzername"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _setup.login,
                            expression: "login",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "username",
                          autocomplete: "username",
                          autofocus: "",
                          name: "username",
                          placeholder: "Your username",
                          type: "text",
                        },
                        domProps: { value: _setup.login },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _setup.login = $event.target.value.trim()
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "password" } }, [
                        _vm._v("Passwort"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _setup.password,
                              expression: "password",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "password",
                            type: _setup.showPassword ? "text" : "password",
                            name: "password",
                            placeholder: "Your password",
                          },
                          domProps: { value: _setup.password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _setup.password = $event.target.value.trim()
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group-append" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              staticStyle: { border: "1px solid !important" },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _setup.showPassword = !_setup.showPassword
                                },
                              },
                            },
                            [
                              !_setup.showPassword
                                ? _c("font-awesome-icon", {
                                    attrs: { icon: "eye" },
                                  })
                                : _c("font-awesome-icon", {
                                    attrs: { icon: "eye-slash" },
                                  }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _setup.errorCode == 428
                      ? _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "mandant" } }, [
                            _vm._v("Mandant"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _setup.manualMandantId,
                                  expression: "manualMandantId",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { id: "mandant", name: "mandant" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _setup.manualMandantId = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { disabled: "", selected: "" },
                                  domProps: { value: null },
                                },
                                [_vm._v("Bitte wählen")]
                              ),
                              _vm._v(" "),
                              _vm._l(_setup.mandanten, function (mandant) {
                                return _c(
                                  "option",
                                  {
                                    key: mandant.label,
                                    domProps: { value: mandant.id },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(mandant.label) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-center mt-3" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          class: _setup.mandantInfo.isEfm
                            ? "btn-efm"
                            : _setup.mandantInfo.isVerdas
                            ? "btn-verdas"
                            : "btn-primary",
                          attrs: { type: "submit" },
                        },
                        [_vm._v("\n                Login\n              ")]
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(
                  " " + _vm._s(_setup.version) + " - " + _vm._s(_setup.profile)
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text-center mt-3" }, [
      _c("h4", { staticClass: "font-weight-bolder" }, [_vm._v("Login")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }